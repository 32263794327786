.flex-basis-50 {
    flex-basis: 50% !important;
}

.flex-basis-100 {
    flex-basis: 100% !important;
}

.justify-self-end {
    justify-self: end;
}

--carousel-control-color {
    color: var(--gp-primary-color);
}


/**
    MAIN CAROUSEL
 */
.carousel-indicators [data-bs-target] {
    background-color: var(--gp-primary-color);
}

#main-carousel .carousel-control-prev,
#main-carousel .carousel-control-next {
    color: #bf3027;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#f00'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
}


/**
BUTTONS
 */

.btn-gp-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--gp-primary-color);
    --bs-btn-border-color: var(--gp-primary-color);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #97202c;
    --bs-btn-hover-border-color: #b02a37;
    --bs-btn-focus-shadow-rgb: 225, 83, 97;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #b02a37;
    --bs-btn-active-border-color: #a52834;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #dc3545;
    --bs-btn-disabled-border-color: #dc3545;
}

.btn-eco-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--eco-primary-color);
    --bs-btn-border-color: var(--eco-primary-color);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #157347;
    --bs-btn-hover-border-color: #146c43;
    --bs-btn-focus-shadow-rgb: 60, 153, 110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #146c43;
    --bs-btn-active-border-color: #13653f;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #198754;
    --bs-btn-disabled-border-color: #198754;
}


/**
CARDS
 */

.card-grid-auto {
    grid-template-columns: auto 1fr;
}

.card-grid-title {
    grid-column: span 2;
}

/**
ALERTS
 */

.alert-sm {
    padding: 0.3rem 0.7rem;
}